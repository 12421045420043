import {
	Bold,
	Heading1,
	Heading2,
	Heading3,
	Paragraph,
} from '../components/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { faCheck, faSquare } from '@fortawesome/free-solid-svg-icons';

import candidatePrivacyNotice from '../files/jacobs-gdpr-candidate-privacy-notice-v1.5 - 22032024.pdf';
import ArmedForces from '../files/armed-forces-covenant.pdf';
import Button from '../components/Button';
import Card from '../components/Card';
import Careers from '../images/careers.jpg';
import Flexbox from '../components/Flexbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '../components/Grid';
import HeroSection from '../components/HeroSection';
import Link from '../components/Link';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Section from '../components/Section';
import defaultTheme from '../utils/themes';
import fetch from '../utils/fetch';
import styled from 'styled-components';
import dcBadge from '../images/dc_badge.svg';
import pdfIcon from '../images/pdf-icon.svg';
import mentalhealthatwork from '../images/mentalhealthatwork.svg';

// const vacanciesUrl = '/api/vacancies';
const vacanciesUrl = 'https://www.jacobsenforcement.com/api/vacancies'; 


const NoVacancies = styled.span`
  background: #fff1f0;
  border: 1px solid #ffa39e;
  padding: 8px 16px;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;
  text-align: center;
`;

const BulletText = styled.p`
  margin-bottom: 0px;
  margin-left: 8px;
  font-weight: 600;
  font-size: 0.9rem;
`;

const VacancyGrid = styled(Grid)`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex-direction: column;
  }
  _:-ms-fullscreen,
  :root {
    flex-direction: column;
  }
`;

const Checkpoint = ({ content }) => (
  <div
    css={`
      display: flex;
      align-items: center;
    `}
  >
    <FontAwesomeIcon icon={faCheck} color='#43c093' size='xs' />
    <BulletText>{content}</BulletText>
  </div>
);

Checkpoint.propTypes = {
  content: PropTypes.string.isRequired,
};

const JobVacancy = ({ content, heading, link }) => (
  <Card
    padding='20px 24px 20px 24px'
    css={`
      h3 {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
      }
    `}
  >
    <Flexbox alignItems='center' justifyContent='space-between'>
      <div>
        <Heading3 color='#9F9F9F' fontSize='0.8rem'>
          {heading}
        </Heading3>
        <Paragraph
          color='text-darkblue'
          fontSize='1.5rem'
          fontWeight='700'
          maxWidth={210}
        >
          {content}
        </Paragraph>
      </div>

      <Button
        onClick={useCallback(() => link && window.open(link, '_blank'), [link])}
        textTransform='capitalize'
      >
        Apply now
      </Button>
    </Flexbox>
  </Card>
);

const OfficeVacancy = ({ link, title, salaryrange }) => {
  const formattedSalaryRange = salaryrange.startsWith('£')
    ? salaryrange
    : `£${salaryrange}`;

  return (
    <Card
      padding='20px 16px 20px 16px'
      css={`
        h3 {
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
        }
      `}
    >
      <Flexbox alignItems='center' justifyContent='space-between'>
        <div style={{ flex: 1, minWidth: 0, marginRight: '10px' }}>
          <Heading3
            style={{
              color: '#11243E',
              fontSize: '0.8rem',
            }}
          >
            {title}
          </Heading3>
          <p
            style={{
              marginTop: '10px',
              fontSize: '13px',
              fontWeight: '800',
              color: '#646464',
              borderRadius: '4px',
            }}
          >
            {formattedSalaryRange}
          </p>
        </div>

        <Button
          onClick={useCallback(() => link && window.open(link, '_blank'), [
            link,
          ])}
        >
          Apply now
        </Button>
      </Flexbox>
    </Card>
  );
};

OfficeVacancy.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  salaryrange: PropTypes.string.isRequired,
};

JobVacancy.propTypes = {
  content: PropTypes.string.isRequired,
  heading: PropTypes.string,
  link: PropTypes.string.isRequired,
};

const ValueContainer = styled.div`
  height: 48px;
  width: 100%;

  padding-left: 24px;
  padding-right: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Value = ({ children }) => (
  <ValueContainer>
    <FontAwesomeIcon icon={faSquare} color='#ffffff' size='xs' />
    <Bold
      color='#ffffff'
      fontSize='0.9rem'
      letterSpacing='0.08rem'
      textAlign='center'
      textTransform='uppercase'
      marginLeft='12px'
    >
      {children}
    </Bold>
  </ValueContainer>
);

Value.propTypes = {
  children: PropTypes.node.isRequired,
};

const CareersPage = () => {
  const [agentVacancies, setAgentVacancies] = useState([]);
  const [arrestVacancies, setArrestVacancies] = useState([]);
  const [officeVacancies, setOfficeVacancies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch.get(vacanciesUrl).then(({ data }) => {
      const foundAgentVacancies = [];
      const foundOfficeVacancies = [];
      const foundArrestVacancies = [];

      const domParser = new DOMParser();
      const xmlDocument = domParser.parseFromString(
        data.trim(),
        'application/xml'
      );
      const items = xmlDocument.querySelectorAll('item');

      for (let item of items) {
        const title = item.querySelector('title')?.textContent || '';
        const salaryrange =
          item.querySelector('salaryrange')?.textContent || '';
        const city =
          item.querySelector('city')?.textContent ||
          item.querySelector('location')?.textContent;
        const link = item.querySelector('link')?.textContent || '';

        if (title.startsWith('Enforcement'))
          foundAgentVacancies.push({ title, city, link, salaryrange });
        else if (title.startsWith('Arrest'))
          foundArrestVacancies.push({ title, city, link, salaryrange });
        else foundOfficeVacancies.push({ title, city, link, salaryrange });
      }

      setAgentVacancies(foundAgentVacancies);
      setArrestVacancies(foundArrestVacancies);
      setOfficeVacancies(foundOfficeVacancies);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <SEO
        title='Careers'
        description={`
                    A career at Jacobs means joining one of the largest Enforcement Agencies in England and Wales. With opportunities available now, you have the chance to join us in a fast-paced, rewarding and dynamic working environment.
                `}
      />
      <HeroSection paddingTop='8px'>
        <Heading1 marginTop='120px'>Careers</Heading1>
        <Paragraph maxWidth='630px'>
          A career at Jacobs means joining one of the largest Enforcement
          Agencies in England and Wales and having the chance to join us in a
          dynamic, fast paced and rewarding working environment.
        </Paragraph>
      </HeroSection>

      <Section
        backgroundColor='white'
        paddingTop='76px'
        paddingBottom='40px'
        css={`
          h2 {
            font-size: 1.35rem;
            margin-bottom: 2;
          }
        `}
      >
        <Heading2>Enforcement Agent Vacancies</Heading2>

        <Paragraph>
          If you want an opportunity to exceed £45,000 per annum, then look no
          further.
        </Paragraph>

        <Paragraph marginBottom='56px'>
          No experience? Don&apos;t worry; we take on Trainees as well as Agents
          who are already certificated.
        </Paragraph>

        <Paragraph>
          <strong>Here are the areas we are currently recruiting to:</strong>
        </Paragraph>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <VacancyGrid
            gridTemplateRows={{
              xs: 'repeat(3, auto)',
              lg: 'auto',
            }}
            gridTemplateColumns={{
              xs: '1fr',
              lg: 'repeat(3, 1fr)',
            }}
            gridGap='20px'
            marginBottom='3rem'
          >
            {agentVacancies.map(({ city, link }, i) => (
              <JobVacancy
                key={i}
                heading='Location'
                content={city}
                link={link}
              />
            ))}

            {!agentVacancies.length && (
              <Paragraph>
                <NoVacancies>There are currently no vacancies</NoVacancies>
              </Paragraph>
            )}
          </VacancyGrid>
        )}
      </Section>
      <Section
        backgroundColor='grey'
        paddingTop='76px'
        paddingBottom='40px'
        css={`
          h2 {
            font-size: 1.35rem;
            margin-bottom: 2;
          }
        `}
      >
        <Heading2>Arrest Officer Vacancies</Heading2>

        <Paragraph marginBottom='56px'>
          If you want a rewarding career where no two days are the same, click
          below for more information. Full training can be provided.
        </Paragraph>

        <Paragraph>
          <strong>Here are the areas we are currently recruiting to:</strong>
        </Paragraph>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <VacancyGrid
            gridTemplateRows={{
              xs: 'repeat(3, auto)',
              lg: 'auto',
            }}
            gridTemplateColumns={{
              xs: '1fr',
              lg: 'repeat(3, 1fr)',
            }}
            gridGap='20px'
            marginBottom='3rem'
          >
            {arrestVacancies.map(({ city, link }, i) => (
              <JobVacancy
                key={i}
                heading='Location'
                content={city}
                link={link}
              />
            ))}

            {!arrestVacancies.length && (
              <Paragraph>
                <NoVacancies>There are currently no vacancies</NoVacancies>
              </Paragraph>
            )}
          </VacancyGrid>
        )}
      </Section>

      <Section
        backgroundColor='white'
        paddingTop='76px'
        paddingBottom='76px'
        css={`
          h2 {
            font-size: 1.35rem;
            margin-bottom: 2;
          }
        `}
      >
        <Heading2>Head Office Vacancies</Heading2>

        <Paragraph marginBottom='56px'>
          If you want to work for a forward thinking company that recognises and
          rewards its workforce, select a vacancy below to find out more.
        </Paragraph>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <VacancyGrid
            gridTemplateRows={{
              xs: 'repeat(3, auto)',
              lg: 'auto',
            }}
            gridTemplateColumns={{
              xs: '1fr',
              lg: 'repeat(3, 1fr)',
            }}
            gridGap='20px'
            marginBottom='3rem'
          >
            {officeVacancies.map(({ link, title, salaryrange }, i) => (
              <OfficeVacancy
                key={i}
                link={link}
                title={title}
                salaryrange={salaryrange}
              />
            ))}

            {!officeVacancies.length && (
              <Paragraph>
                <NoVacancies>There are currently no vacancies</NoVacancies>
              </Paragraph>
            )}
          </VacancyGrid>
        )}
      </Section>

      <Section paddingTop='80px' paddingBottom='70px' backgroundColor='grey'>
        <Grid
          gridTemplateRows={{
            xs: 'auto auto',
            md: 'auto',
          }}
          gridTemplateColumns={{
            xs: '1fr',
            md: '1fr 1fr',
          }}
          gridGap={{
            xs: '40px',
            md: '80px',
          }}
          ieCardLayout
        >
          <div>
            <Paragraph>
              We look for people who are ambitious and want to develop a career,
              while making a strong contribution to Jacobs’ long term and
              sustainable growth. To ensure we achieve this growth in the right
              way, we place great emphasis on our values and are committed to
              applying the highest standards to all that we do. We want our
              workforce to speak up for what is right.
            </Paragraph>
            <Paragraph>
              Joining Jacobs will give you the chance to work in an innovative,
              supportive and inclusive environment in which we seek to develop
              and promote people based on merit. We provide extensive, tailored
              training as well as a range of market-competitive benefits.
            </Paragraph>
          </div>
          <img
            src={Careers}
            alt='An image from a Jacobs Enforcement Awards event.'
          />
        </Grid>
      </Section>

      <Section
        backgroundColor='darkblue'
        paddingTop='32px'
        paddingBottom='40px'
        css={`
          h2 {
            font-size: 1.6rem;
            margin-bottom: 2;
          }
          p {
            font-size: 1.1rem;
          }
          h2,
          p {
            text-align: center;
          }
        `}
      >
        <div
          css={`
            max-width: 768px;
            margin: 0 auto;
          `}
        >
          <Heading2 color='white'>Our Values</Heading2>

          <Paragraph color='white' lineHeight='1.6rem' fontWeight='500'>
            At Jacobs we live by our values. They define who we are and how we
            operate.
          </Paragraph>
        </div>

        <Grid
          gridTemplateRows={{
            xs: 'repeat(4, auto)',
            md: 'repeat(2, auto)',
            lg: 'auto',
          }}
          gridTemplateColumns={{
            xs: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gridGap='20px'
        >
          <Value>Fairness</Value>
          <Value>Advancement</Value>
          <Value>Collaboration</Value>
          <Value>Excellence</Value>
        </Grid>
      </Section>

      <Section
        backgroundColor='white'
        paddingTop='56px'
        paddingBottom='32px'
        css={`
          h2 {
            font-size: 1.35rem;
            margin-bottom: 2;
          }
        `}
      >
        <Heading2 textAlign='center' marginBottom='2rem'>
          Benefits include
        </Heading2>

        <Grid
          ieCardLayout
          gridTemplateRows={{
            xs: 'repeat(9, auto)',
            lg: 'repeat(3, auto)',
          }}
          gridTemplateColumns={{
            xs: '1fr',
            lg: 'repeat(3, 1fr)',
          }}
          gridGap='24px'
        >
          <Checkpoint content='Competitive Basic Salary' />
          <Checkpoint content='Generous Holiday Allowance' />
          <Checkpoint content='Pension' />
          <Checkpoint content='Employee Rewards System' />
          <Checkpoint content='Recognition Awards' />
          <Checkpoint content='Annual Company Day' />
        </Grid>
        <Link
          href={ArmedForces}
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            margin: '16px 0',
          }}
        >
          Jacobs is an Armed Forces friendly employer.
          <img
            src={pdfIcon}
            style={{ marginLeft: '8px', marginTop: '2px' }}
          ></img>
        </Link>
        <Link
          href={candidatePrivacyNotice}
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            margin: '16px 0 32px',
          }}
        >
          Candidate Privacy Notice
          <img
            src={pdfIcon}
            style={{ marginLeft: '8px', marginTop: '2px' }}
          ></img>
        </Link>
        <Badges>
          <img
            src={mentalhealthatwork}
            alt='Mental Health at Work Commitment badge'
          />
          <img src={dcBadge} alt='Disability Confident Committed badge' />
        </Badges>
      </Section>
    </>
  );
};

const Badges = styled.div`
	display: flex;
	justify-content: center;
	grid-gap: 40px;
	flex-wrap: wrap;
`;

CareersPage.propTypes = {
	theme: PropTypes.object,
};

CareersPage.defaultProps = {
	theme: defaultTheme,
};

export default CareersPage;
